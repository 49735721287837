.functions {
  display: flex;
  color: black;
  font-size: 18px;
  justify-content: center;
}

#form {
display: flex;
justify-content: end;
}

.search {
  border-radius: 10px;
}

.search::placeholder {
   color: #7378c5;
 }

.movie_container {
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  justify-content: center;
}

.function1 {
  width: 250px;
  height: 250px;
}
.movie {
  cursor: pointer;
  width: 250px;
  margin: 1rem;
  padding: 10px;
  /* background-color: red; */
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}

.movie:hover {
  transform: scale(1.1);
}

.overview {
  background-color: #fff;
  padding: 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 100%;
  transform: translateY(101%);
  overflow-y: auto;
  transition: transform 0.3s ease-in;
  white-space: pre-line;
}

.movie:hover .overview {
  transform: translateY(0);
}
.overview_con {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.demo {
  border-radius: 5px;
  border: 1px solid blue;
  margin-bottom: 5px;
  padding: 5px;
  font-size: 16px;
  background-color: beige;
}

.demo:hover {
  opacity: 0.5;
}