.card-profile {
  font-family: 'Hind Guntur', sans-serif;
  color: #054231;
  display: flex;
  justify-content: center;
  /* background: #49afa8; */
  /* background-image: url('https://github.com/OlgaKoplik/CodePen/blob/master/leaf.png?raw=true'); */
  background-repeat: no-repeat, no-repeat;
  background-position: 200% -40%;
  background-size: 80%;
}

.card {
  width: 310px;
  height: 627px;
  padding: 15px;
  margin-top: 40px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  box-shadow: 15px 10px 25px 0px;
  background: #fff;
  /* background-image: url('https://github.com/OlgaKoplik/CodePen/blob/master/leaf2.png?raw=true'), url('https://github.com/OlgaKoplik/CodePen/blob/master/leaf.png?raw=true'); */
  background-repeat: no-repeat, no-repeat;
  background-position: 120% -5%, 200% -40%;
  background-size: 40%, 80%;
  animation: open .5s;
}

@keyframes open {
  0% {
    background-position: 166% -25%, 220% -69%;
  }

  100% {
    background-position: 120% -5%, 200% -40%;
  }
}

/* form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.field {
  margin: 5px;
  display: flex;
  flex-direction: column;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  background: linear-gradient(270deg, #3fa1a9, #79f1a4);
  /* background: linear-gradient(270deg, blue, yellow); */
  margin-bottom: 25px;
}

.img-wrap {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

.img-upload:before {
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #63d3a6;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  opacity: 0;
  transition: .5s ease;
  background-color: #fff;
}

.img-upload:hover:before {
  opacity: 1;
}

/* img {
  width: auto;
  height: 100%;
} */

label {
  text-transform: uppercase;
  font-weight: 700;
  color: #676767;
}

#name,
#status {
  border-radius: 15px;
  border: 1px solid #b7b7b7;
  padding: 5px 5px 5px 10px;
  font-size: 18px;
  transition: 0.2s;
}

#name:focus,
#status:focus {
  outline: none;
  border: 1px solid #64d488;
}

input::placeholder {
  color: #bebebe;
}

.name {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #676767;
  max-width: 220px;
  overflow-wrap: break-word;
}

.status {
  text-align: center;
  max-width: 220px;
  overflow-wrap: break-word;
}

.edit,
.save {
  position: relative;
  color: white;
  letter-spacing: 1px;
  margin: 20px;
  font-size: 18px;
  padding: 10px;
  text-align: center;
  transition: 0.5s;
  border-radius: 10px;
  cursor: pointer;
  border-radius: 25px;
  border: none;
  background: blue;
}

.save {
  font-weight: 600;
  left: -20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  /* width: 20px; */
  box-shadow: 20px 0px 40px 0px #63d3a6;
}

.edit {
  color: #fff;
  width: 180px;
}

.edit:hover,
.save:hover {
  left: 0;
  color: #fff;
  width: 180px;
  box-shadow: 0px 0px 20px 0px #63d3a6;
}

.edit:focus,
.save:focus {
  outline: none;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}


.fa-instagram {
  position: absolute;
  color: #79f1a4;
  top: 3%;
  right: 2%;
  font-size: 38px;
}

.fa-instagram:hover {
  font-size: 42px;
  color: #caff81;
  transition: all .1s linear;
  cursor: pointer;
}